import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({ onCloseForm }) => {
  const [showSubmit, setShowSubmit] = useState(false);

  const [formData, setFormData] = useState({
    company: '',
    name: '',
    email: '',
    description: ''
});
const [successMessage, setSuccessMessage] = useState('');

const handleSubmit = (e) => {
    e.preventDefault();
    fetch('send_email.php', {
        method: 'POST',
        body: new FormData(e.target)
    })
    .then(response => response.text())
    .then(data => {
        console.log(data);
        // Handle success
        setSuccessMessage('Your message has been sent. We will connect with you soon.');
        // Clear form
        setFormData({
            company: '',
            name: '',
            email: '',
            description: ''
        });
    })
    .catch(error => {
        console.error('Error:', error);
        // Handle error
    });
};
  // Handle reCAPTCHA change event
  const onChange = () => {
    setShowSubmit(true);
  }
  const keys = {
    'localhost': '6LfaPr8pAAAAAKg2HibG6JjBl0I8zFMsYjbP-HJx',
    'weevaluate.ai': '6Ld9L8ApAAAAAA6qSAw-nIyfl8rVOix5EkEPunS9',
    'dev.weevaluate.ai': '6Ld9L8ApAAAAAA6qSAw-nIyfl8rVOix5EkEPunS9',
    // Add more host-key pairs as needed
  };
  
  const getCurrentHost = () => {
    return window.location.hostname;
  };
  
  const getSiteKey = () => {
    const host = getCurrentHost();
    return keys[host] || '6LfaPr8pAAAAAKg2HibG6JjBl0I8zFMsYjbP-HJx'; // Use a default key if the host is not found in the keys object
  };
  

  return (

    <div className="bg-white p-8 rounded-lg w-full max-w-md relative">
    <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
    <form onSubmit={handleSubmit}>
        <div className="mb-3">
            <label htmlFor="company" className="block mb-1">Company Name</label>
            <input type="text" id="company" name="company" placeholder="Enter your company name" className="input h-8 w-full border" required />
        </div>
        <div className="mb-3">
            <label htmlFor="name" className="block mb-1">Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your name" className="input h-8 w-full border" required />
        </div>
        <div className="mb-3">
            <label htmlFor="email" className="block mb-1">Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email" className="input h-8 w-full border" required />
        </div>
        <div className="mb-3">
            <label htmlFor="phone" className="block mb-1">Description</label>
            <textarea  id="phone" name="phone" placeholder="Enter Description " className="input h-20 w-full border" required > </textarea>
        </div>
        
        {/* Google reCAPTCHA */}
        <ReCAPTCHA
            sitekey={getSiteKey()}
            onChange={onChange}
        />
        <div className="flex justify-between p-3">
            <button type="button" onClick={onCloseForm} className="bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-md transition duration-300">Cancel</button>

<button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md transition duration-300" disabled={!showSubmit}>Submit</button>
{successMessage && <p className="text-green-500">{successMessage}</p>}
       
        </div>
    </form>
</div>

   
  );
};

export default ContactForm;
