import React, { useState, useEffect } from "react";
import intro from "./assets/intro.png";
import about from "./assets/about.png";
import howitwork from "./assets/howitwork.png";
import partner from "./assets/partner.png";
import contact from "./assets/contact.png";
import logo from "./assets/logo.png";

import ContactForm from './pages/main/ContactUs/ContactForm';
function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const smoothScroll = () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();

          const target = document.querySelector(this.getAttribute("href"));
          if (target) {
            const headerOffset = document.querySelector("header").offsetHeight;
            window.scrollTo({
              top: target.offsetTop - headerOffset,
              behavior: "smooth",
            });

            // Close the menu on mobile after clicking a link
            setIsMenuOpen(false);
          }
        });
      });
    };

    smoothScroll();

    // Cleanup event listener on component unmount
    return () => {
      document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
        anchor.removeEventListener("click", smoothScroll);
      });
    };
  }, []);

  return (
    <>
      <header className="bg-white text-gray-900 py-6 fixed w-full top-0 z-50">
        <div className="container mx-auto flex justify-between items-center px-4">
        <div className="flex items-center">
    <img
        src={logo}
        alt="WeEvaluate Image"
        className="h-8 md:h-auto mr-2"
    />
    <h1 className="text-2xl font-bold text-gray-900">WeEvaluate</h1>
</div>
          <button className="md:hidden focus:outline-none" onClick={toggleMenu}>
            <svg
              className="w-6 h-6 fill-current text-gray-900"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 5h18v2H3V5zm0 6h18v2H3v-2zm18 6H3v2h18v-2z"
              />
            </svg>
          </button>
          <nav className="hidden lg:flex flex-col space-y-2">
            <ul className="hidden lg:flex flex-row space-x-4">
              <li>
                <a
                  href="#intro"
                  className="block px-4 py-2 hover:text-gray-400"
                >
                  Intro
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className="block px-4 py-2 hover:text-gray-400"
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#how-it-works"
                  className="block px-4 py-2 hover:text-gray-400"
                >
                  How it works
                </a>
              </li>
              <li>
                <a
                  href="#partner"
                  className="block px-4 py-2 hover:text-gray-400"
                >
                  Partner
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className="block px-4 py-2 hover:text-gray-400"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Sidebar Menu */}
      <nav
        className={`lg:hidden bg-white text-gray-900 w-64 h-full fixed top-0 left-0 z-20 transition-transform duration-300 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } ${isMenuOpen ? "md:hidden" : "hidden"}`}
      >
        <ul className="flex flex-col space-y-2 py-20">
          <li>
            <a href="#intro" className="block px-4 py-2 hover:text-gray-400">
              Intro
            </a>
          </li>
          <li>
            <a href="#about" className="block px-4 py-2 hover:text-gray-400">
              About
            </a>
          </li>
          <li>
            <a
              href="#how-it-works"
              className="block px-4 py-2 hover:text-gray-400"
            >
              How it works
            </a>
          </li>
          <li>
            <a href="#partner" className="block px-4 py-2 hover:text-gray-400">
              Partner
            </a>
          </li>
          <li>
            <a href="#contact" className="block px-4 py-2 hover:text-gray-400">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>

      <div>
        <section
          id="intro"
          className="bg-gray-200 text-gray-900 py-16 h-screen flex flex-col justify-center items-center"
        >
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div className="md:flex md:items-center">
              <img
                src={intro}
                alt="WeEvaluate Image"
                className="mx-auto h-64 md:h-auto"
              />
            </div>
            {/* Right Column */}
            <div className="md:flex md:items-center">
              <div className="max-w-md mx-auto">
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold mb-4">
                    Revolutionizing Recruitment
                  </h2>
                  <p className="text-lg mb-4">
                    Embark on a journey from frustration to efficiency with
                    WeEvaluate!
                  </p>
                  <p className="text-lg mb-4">
                    Say goodbye to time-consuming walking interviews and the
                    pressure of selecting the right candidate.
                  </p>
                  <p className="text-lg mb-4">
                    Our platform simplifies the hiring process, empowering HR
                    professionals to make informed decisions with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="about"
          className="py-16 h-screen bg-gray-200 flex flex-col justify-center items-center"
        >
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div className="md:flex md:items-center md:w-full">
              <div className="max-w-md mx-auto">
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold mb-8">About WeEvaluate</h2>
                  <p className="text-lg mb-4 ">
                    It leverages advanced Generative AI technology to
                    personalize interview questions for each candidate based on
                    the job description and their resume.
                  </p>
                  <p className="text-lg mb-4">
                    This personalization ensures that every interview is highly
                    relevant, leading to more accurate assessments and better
                    candidate fits for specific roles.
                  </p>
                  <p className="text-lg mb-4">
                    WeEvaluate is dedicated to making hiring more efficient,
                    fair, and effective by reducing bias, saving time, and
                    improving the quality of candidate evaluations.
                  </p>
                </div>
              </div>
            </div>
            {/* Right Column */}

            <div className="md:flex md:items-center ">
              <img
                src={about}
                alt="WeEvaluate Image"
                className="mx-auto h-64 md:h-auto"
              />
            </div>
          </div>
        </section>

        <section
          id="how-it-works"
          className="bg-gray-100 py-16 h-screen  flex flex-col justify-center items-center"
        >
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div className="md:flex md:items-center">
              <img
                src={howitwork}
                alt="WeEvaluate Image"
                className="mx-auto h-64 md:h-auto"
              />
            </div>
            {/* Right Column */}
            <div className="md:flex md:items-center">
              <div className="max-w-md mx-auto">
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold mb-4">How it works</h2>
                  <p className="text-lg mb-4">
                    Transforming your hiring process has been easier with
                    WeEvaluate! Just select the job role you're hiring for,
                    upload the candidate's resume, and voila! Our advanced
                    system analyzes the resume and generates tailored interview
                    questions. Review the insightful analysis, send out
                    invitations, and leave the rest to us. With WeEvaluate,
                    you're not just hiring; you're revolutionizing your
                    recruitment journey!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="partner"
          className="py-16 h-screen bg-gray-200  flex flex-col justify-center items-center"
        >
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div className="md:flex md:items-center md:w-full">
              <div className="max-w-md mx-auto">
                <div className="bg-white shadow-md rounded-lg p-6">
                  <h2 className="text-2xl font-bold mb-4">Partner Portal!</h2>
                  <button
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 mb-6"
                    onClick={() =>
                      window.open("https://partner.weevaluate.ai/", "_blank")
                    }
                  >
                    Log In to Partner Portal
                  </button>
                  <p className="text-lg mb-4">
                    Explore our powerful recruitment platform designed to
                    simplify and enhance the hiring process. With WeEvaluate,
                    you can access personalized interview solutions that
                    revolutionize the way you evaluate candidates. Our advanced
                    technology analyzes resumes and job descriptions to create
                    tailored interview questions, ensuring a seamless and
                    efficient recruitment experience. Join us and unlock the
                    potential of modern hiring today!
                  </p>
                </div>
              </div>
            </div>
            {/* Right Column */}

            <div className="md:flex md:items-center ">
              <img
                src={partner}
                alt="WeEvaluate Image"
                className="mx-auto h-64 md:h-auto"
              />
            </div>
          </div>
        </section>

        <section
          id="contact"
          className="py-16 h-screen bg-gray-200  flex flex-col justify-center items-center"
        >
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            {/* Left Column */}
            <div className="md:flex md:items-center">
              <img
                src={contact}
                alt="WeEvaluate Image"
                className="mx-auto h-64 md:h-auto"
              />
            </div>
            {/* Right Column */}
            <div className="md:flex md:items-center">
              <div className="max-w-md mx-auto">
                <div className="bg-white shadow-md rounded-lg p-6">
                 <ContactForm/>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="bg-gray-900 text-white py-4 ">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 WeEvaluate. All rights reserved.</p>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
